.btn {
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  border-radius: 8px;
  font-size: 1.1rem;
}

/* size */
.btn--normal {
  padding: 10px 16px;
}

.btn--small {
  padding: 5px 11px;
}

/* color */
.btn--primary {
  background-color: var(--primary-color-main);
  color: var(--white-color);

  &:hover {
    background-color: var(--primary-color-300);
  }
}

.btn--transparent {
  background-color: transparent;
  color: var(--primary-color-main);

  &:hover {
    background-color: transparent;
  }
}

.btn--ghost {
  background-color: transparent;
  color: var(--primary-color-main);
  border: 1px solid var(--primary-color-main);

  &:hover {
    background-color: transparent;
  }
}
